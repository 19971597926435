import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/post-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><img parentName="p" {...{
        "src": "https://res.cloudinary.com/bethbecerra/image/upload/v1638996055/lupita_nmo4yo.png",
        "alt": "guadalupe-muriel"
      }}></img></p>
    <h3>{`Licenciada en Letras Latinoamericanas, directora de teatro juvenil e infantil, docente de humanidades, editora de esta bella revista, pero sobre todo: escritora apenas salida del closet de los artistas. Su escritura es breve, directa y reflexiva. “Decir sólo lo estrictamente necesario” es la constante en sus minificciones y en su literatura, en general.`}</h3>
    <hr></hr>
    <h2>{`¿Cómo odiar?`}</h2>
    <p>{`La primera vez que dije que lo odiaba lo dije enserio y con mucha tristeza. El odio no es un concepto que se use con ligereza. La fuerza del odio se siente en el cuerpo, en el pensamiento y en el alma… sentimiento profundo. Úsese sólo en casos de extrema necesidad y, quizá, de profunda tristeza.`}</p>
    <hr></hr>
    <h2>{`Somos…`}</h2>
    <p>{`Y es que somos el resultado de las personas que amamos… el tiempo marca el ritmo de la vida, un compás constante que recuerda cómo otro instante se ha ido… para siempre.`}</p>
    <p>{`Uno se convierte en las cosas que ama, que extraña… y el tiempo no para. Mira aquí estoy frente a ti, con toda una vida a cuestas, aun así una parte de mí sigue siendo tuya, aquella parte que no quiero volver a ser.`}</p>
    <hr></hr>
    <h2>{`Verano`}</h2>
    <p>{`El verano llegó siendo más verano que nunca y más hippie que siempre, podía sentirlo en mis venas, el jazz en los audífonos y, sobre todo en la calurosa, húmeda y esplendida noche estrellada que me regaló la luna nueva del 21 de junio.`}</p>
    <p>{`Con la ola de calor llegaron las ganas de una cerveza en el fresco de la terraza para comenzar a dormitar y relajarme al fin, mañana ya sería verano, lo cual me permitía sumirme en un sueño pesado… relajante… `}<em parentName="p">{`Summer time`}</em>{`, and the `}<em parentName="p">{`living`}</em>{` is easy. Indeed.`}</p>
    <hr></hr>
    <h2>{`Toluca`}</h2>
    <blockquote>
      <p parentName="blockquote">{`Adiós Toluca`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`De mis amores,`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Fríos y sin sabores`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Adiós Toluca`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Siempre…adiós.`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      